
<template>
  <div class="order-detail-box" v-loading="pageLoading">
    <div class="order-tips">订单已完成支付，感谢您在捷盈网站平台入驻下单！！！</div>
    <div class="order-goods-info">
      <div class="order-goods-img">
        <img :src="orderDetailData.goodsImg" alt="" width="500px" height="350px" style="border-radius: 12px;">
      </div>
      <div class="order-good-box">
        <div class="order-info">
          <div class="order-info-tit">订单信息</div>
          <div class="order-txt">订单号：{{ orderDetailData.orderId}}</div>
          <div class="order-txt">订单时间：{{ orderDetailData.payTime}}</div>
          <!-- <div class="order-txt">开始时间：{{ orderDetailData.beginTime}}</div> -->
          <div class="order-txt">到期时间：{{ orderDetailData.endTime}}</div>
          <div class="order-txt">有效期：{{ orderDetailData.endTime|handleCulateTime }}</div>
        </div>
        <div class="order-info-tit">商品信息</div>
        <div class="order-txt">商品名称：{{ orderDetailData.goodsName}}</div>
        <div class="order-txt">年限：{{ orderDetailData.timeLimitVal}}</div>
        <div class="order-txt">支付价：¥{{ orderDetailData.payPrice}}</div>
        <div class="order-txt">原价：¥{{ orderDetailData.originalPrice}}</div>
        <div class="order-pay-box">
          <div class="order-info-tit">支付信息</div>
          <div class="order-txt">支付方式: {{ orderDetailData.payType|handlePayTiype}}</div>
          <div class="order-txt">支付金额: ¥{{ orderDetailData.payPrice}}</div>
          <div class="order-txt">支付时间: {{ orderDetailData.payTime}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  props: {
    orderDetailData: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {
      pageLoading: false,
    }
  },
  filters: {
    handleCulateTime (endDate) {
      // 计算相隔天数
      const start = dayjs(dayjs().format('YYYY-MM-DD'))
      const end = dayjs(endDate)
      const diffInDays = end.diff(start, 'day')

      // 转换为「n年n月n天」格式
      const years = Math.floor(diffInDays / 365);
      const months = Math.floor((diffInDays % 365) / 30);
      const days = diffInDays % 30;
      return `${years}年${months}月${days}天`
    },
    handlePayTiype (type) {
      if (type) {
        const payType = {
          0: '微信支付',
          1: '支付宝支付'
        }
        return payType[type]
      }
    }
  },
  activated () {
  },
  created () {
  },
  mounted () {
    const rowData = JSON.parse(localStorage.getItem('rowData'))
    this.orderDetailInfo = rowData
  },
  watch: {},
  methods: {

  }
};
</script>
<style lang="scss" scoped>
.order-detail-box {
  width: 1300px;
  margin: 0 auto;
  margin-top: 130px;
  padding: 40px;
  height: 67vh;
  background-color: #fff;
  border-radius: 12px;
  .order-tips {
    margin-bottom: 20px;
  }
  .order-goods-info {
    display: flex;
    .order-good-box {
      margin-left: 50px;
      .order-info {
        .order-info-tit {
          margin-bottom: 20px;
          font-weight: bold;
        }
        .order-txt {
          margin-bottom: 15px;
          margin-left: 40px;
        }
      }
    }
  }
}

// .order-good-box {
//   margin-left: 50px;
// }
// .order-info-tit {
//   margin-bottom: 20px;
//   font-weight: bold;
// }
// .order-txt {
//   margin-bottom: 15px;
//   margin-left: 40px;
// }
</style>